<template>
    <v-container grid-list-xl fluid>
        <v-layout wrap v-if="!showMode" class="d-print-none">
            <v-flex xs12>
                <h1>Relatório de operação</h1>
                <v-divider class="mb-4" />
            </v-flex>
            <v-flex xs12 sm4 md5>
                <v-autocomplete v-model="selectedStore" :items="stores" item-text="name" item-value="name" label="Loja" return-object :disabled="!loaded" :loading="!loaded"
                    clearable>
                </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm4 md5>
                <DateInterval @onChange="dates = $event" limit="365"></DateInterval>
            </v-flex>
            <v-flex xs12 sm4 md2 text-center>
                <v-btn :disabled="processing" color="success" @click="get">Gerar Relatório</v-btn>
            </v-flex>
        </v-layout>
        <v-layout wrap v-else class="d-print-none">
            <v-flex xs12 sm12 text-center>
                <v-btn @click="showMode = false" small color="error">Fechar Relatório<v-icon right dark> cancel </v-icon></v-btn>
            </v-flex>

            <v-flex xs12 sm12 text-center class="d-print-none">
                <DownloadCsv :data="csv" name="relatorio.csv" :labels="csvLabels" delimiter=";">
                    <v-btn color="success">Download em CSV<v-icon right dark> download </v-icon></v-btn>
                </DownloadCsv>
            </v-flex>
        </v-layout>

        <v-progress-linear v-if="processing || !loaded" indeterminate></v-progress-linear>

        <v-layout wrap v-if="showMode">
            <v-flex xs12>
                <h2 v-if="this.selectedStore">Loja: {{ this.selectedStore.name }}</h2>
            </v-flex>
            <v-flex xs12>
                <h2>
                    {{
                        this.dates.length == 2
                            ? `De: ${$moment(this.dates[0]).format("DD/MM/YYYY")} Até: ${$moment(this.dates[1]).format("DD/MM/YYYY")}`
                            : `${$moment(this.dates[0]).format("DD/MM/YYYY")}`
                    }}
                </h2>
            </v-flex>
            <v-flex xs12 sm6 md6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>functions</v-icon>
                            {{ total }}
                        </div>
                        Número de entregas
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6 md6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>two_wheeler</v-icon>
                            {{ totalKM }}
                        </div>
                        Km rodados
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6 md6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>groups</v-icon>
                            {{ experts }}
                        </div>
                        Número de diferentes especialistas
                    </v-card-text>
                </v-card>
            </v-flex>
            <!-- <v-flex xs12 sm6 md6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>face</v-icon>
                            {{ totalExperts }}
                        </div>
                        Número total de turnos
                    </v-card-text>
                </v-card>
            </v-flex> -->
            <v-flex xs12 sm6 md6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>recycling</v-icon>
                            {{ CO2 }} Kg
                        </div>
                        Economia de CO<sub>2</sub>
                    </v-card-text>
                </v-card>
            </v-flex>

            <v-flex xs12 sm12 md12 v-if="items.length > 0">
                <v-data-table :headers="headers" :items="items" :items-per-page="-1" class="elevation-2" hide-default-footer :loading="!items">
                    <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                    <template v-slot:[`item.km`]="{ item }">
                        {{ item.odometer == null || isNaN(item.odometer) ? "-" : `${Number.parseFloat(item.odometer).toFixed(2)} km` }}
                    </template>
                    <template v-slot:[`item.dateIn`]="{ item }">{{ $moment(item.dateIn).format("DD/MM/YYYY HH:mm") }}</template>
                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import DownloadCsv from "vue-json-csv";

import DateInterval from "@/components/core/DateInterval";

export default {
    components: {
        DateInterval,
        DownloadCsv,
    },
    data() {
        return {
            showMode: false,
            loaded: false,
            dates: [],
            processing: false,
            items: [],
            stores: [],
            selectedStore: null,
            csvLabels: {
                dateIn: "Data de entrada",
                dateOut: "Data de saída",
                expertName: "Nome do Especialista",
                expertCPF: "CPF do Especialista",
                odometer: "Km rodados",
                deliveryNumber: "Números de entregas",
                store: "Loja",
            },
        };
    },

    created() {
        this.$http
            .get("stores?enable=true")
            .then((result) => {
                this.stores = result;
                this.loaded = true;
            })
            .catch(() => {
                this.stores = [];
            });
    },

    methods: {
        get() {
            if (!this.processing) {
                this.processing = true;

                let query = this.dates.length == 2 ? `?from=${this.dates[0]}&to=${this.dates[1]}` : `?from=${this.dates[0]}&to=${this.dates[0]}`;

                if (this.selectedStore) {
                    query += `&store=${this.selectedStore.id}`;
                }

                this.$http
                    .get(`checkin/report${query}&timezone=-3`)
                    .then((result) => {
                        this.items = result;
                        this.processing = false;
                        this.showMode = true;
                    })
                    .catch(() => {
                        this.processing = false;
                    });
            }
        },
    },

    computed: {
        csv() {
            if (this.items.length > 0) {
                return this.items.map((i) => {
                    return {
                        dateIn: i.dateIn ? this.$moment(i.dateIn).format("DD/MM/YYYY HH:mm") : null,
                        dateOut: i.dateOut ? this.$moment(i.dateOut).format("DD/MM/YYYY HH:mm") : null,
                        expertName: i.expert.name,
                        expertCPF: i.expert.cpf,
                        odometer: i.odometer,
                        deliveryNumber: i.deliveryNumber,
                        store: i.store,
                    };
                });
            }

            return null;
        },
        headers() {
            let headers = [
                { text: "Data", value: "dateIn", align: "center" },
                { text: "Especialista", value: "expert.name", align: "center" },
                { text: "CPF", value: "expert.cpf", align: "center" },
                { text: "Número de entregas", value: "deliveryNumber", align: "center" },
                { text: "Km rodados", value: "km", align: "center" },
            ];

            if (!this.selectedStore) {
                headers.push({
                    text: "Local",
                    align: "left",
                    value: "store",
                    visibility: false,
                });
            }

            return headers;
        },
        total() {
            return Number.parseFloat(this.items.reduce((sum, i) => (i.deliveryNumber ? sum + i.deliveryNumber : sum), 0)).toFixed(0);
        },
        totalKM() {
            return Number.parseFloat(this.items.reduce((sum, i) => (i.odometer ? sum + i.odometer : sum), 0)).toFixed(2);
        },
        CO2() {
            return Number.parseFloat(this.totalKM * 0.13).toFixed(2);
        },
        experts() {
            let obj = new Object();

            for (let i = 0, len = this.items.length; i < len; i++) {
                obj[this.items[i].expert.cpf] = null;
            }

            return Object.keys(obj).length;
        },
        totalExperts() {
            return Number.parseFloat(this.items.length).toFixed(0);
        },
    },
};
</script>